<template>
    <member-edit
        :detail="work_detail"
        :need-service="this.service"
        :handle-image="handleUploadImage"
        @handle-submit="handleSubmit">
    </member-edit>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";
    export default {
        data() {
            return {
				service:false
            }
        },
		mounted() {
			this.decide();
		},
        computed: mapState({
            user_id: state => state.user.user_id,
            work_detail: state => state.user.work_detail
        }),
        components: {
            memberEdit: () => import("@/components/centerMemberEdit")
        },
        methods: {
            ...mapMutations([
                'setMemberGoodsDetail'
            ]),
            ...mapActions([
                'getMemberGoodsDetail',
                'handleUploadImage',
                'editMemberGoods'
            ]),
			//判断是城市摄影师、斜杠青年
			decide(){
				if(this.$route.name==="centerLensmanChief"||this.$route.name==="centerLensmanSlash"){
					this.service = true
				}else{
					this.service = false
				}
			},
			
            // 查
            getData(goodsID) {
                let userID = this.user_id;
                if(goodsID){
                    this.getMemberGoodsDetail({userID, goodsID});
                }
            },
            // 增
            // 改
            handleSubmit(form, position) {
                let userID = this.user_id,
                    goodsID = this.$route.query.id || '',
                    routeName = this.$route.name,
                    tagID = '',
                    detail = [],
                    cover = form.goods_cover,
                    params = {
                        userID,
                        goodsID,
                        openBegin: form.openBegin,
                        openEnd: form.openEnd,
                        goodsType:form.serviceType,
                        name: form.goods_name,
                        skin: cover.length > 0? cover[0].url :'',
                        sketch: form.describe,
                        area: form.area,
                        address: form.address,
                        position: {
                            long: position.lng,
                            lat: position.lat
                        },
                        price: form.goods_price,
                        unit: form.price_unit,
                    };
                    
                params.url = goodsID? 'usercenter/editGoods': 'usercenter/addGoods';

                switch (routeName){
                    case 'centerLensmanCelebrity':
                        tagID = '2'
                        break;
                    case 'centerLensmanSlash':
                        tagID = '3'
                        break;
                    case 'centerDresserCelebrity':
                        tagID = '4'
                        break;
                    default:
                        tagID = '1'
                        break;
                }
                params.tagID = tagID;
                form.goods_pic.map(item => {
                    detail.push(item.url)
                })
                params.detail = detail.join('|');

                this.editMemberGoods(params)
                    .then(res => {
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: res.type? 'success': 'error'
                        })
                        res.type && this.$router.go(-1);
                    }).catch(err => err)
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                let id = to.query.id;

                vm.getData(id)
            })
        },
        beforeRouteUpdate (to, from, next) {
            let id = to.query.id;

            this.getData(id)
            next();
        },
        beforeRouteLeave (to, from, next) {
            this.setMemberGoodsDetail({})
            next();
        }
    }
</script>